<template>
  <v-select
    v-model="selectedCompany"
    :items="companies"
    @change="setCompany($event)"
    item-text="name"
    return-object
    :label="'Select Company' + (optional ? ' (optional)' : '')"
    prepend-inner-icon="business"
    outlined
    dense
  >
  </v-select>
</template>

<script>
export default {
  model: {
    prop: "company",
    event: "select",
  },
  props: {
    company: Object,
    optional: Boolean,
  },
  data() {
    return {
      companies: [],
      selectedCompany: null,
    };
  },
  watch: {
    company: {
      handler(newVal) {
        if (!newVal) {
          this.selectedCompany = null;
        }
      },
    },
  },
  methods: {
    setCompany(company) {
      this.$emit("chosen", company);
    },
    async loadInitialData() {
      let companyArray = await this.$root.sharedData.getCompanies();
      if (companyArray) {
        this.companies = companyArray;
      }
    },
  },
  created() {
    this.loadInitialData();
    if (this.company && Object.keys(this.company).length > 0) {
      if (this.company.code) {
        this.selectedCompany = this.company.code;
      }
    }
  },
};
</script>

<style>
</style>