<template>
  <div>
    <v-card :loading="loading" class="mb-4 px-5 pb-5">
      <h1 class="pt-5 text-h4">Reconciliation Report</h1>
      <v-divider />
      <v-row>
        <v-col cols="3">
          <v-select
            v-model="selectedCarrier"
            :items="filteredCarriers"
            item-text="name"
            item-value="id"
            dense
            outlined
            menu-props="auto"
            label="Select Carrier"
            :loading="loading"
          ></v-select>
        </v-col>
        <v-col cols="3">
          <v-select
            v-if="!selectedDates || selectedDates.length < 2"
            v-model="selectedInvoiceNumber"
            :items="filteredInvoiceNumbers"
            item-text="invoice_number"
            item-value="invoice_number"
            dense
            outlined
            menu-props="auto"
            label="Select Invoice Number"
            :loading="loading"
          ></v-select>
        </v-col>

        <date-picker
          v-if="!selectedInvoiceNumber"
          v-model="selectedDates"
          @clicked="setDateRange"
          :colWidth="3"
        ></date-picker>
        <v-col cols="3" v-else> </v-col>
        <v-col cols="3">
          <company-select
            @chosen="setCompany"
            :company="selectedCompany"
          ></company-select>
        </v-col>
        <v-col cols="2">
          <v-btn
            :loading="loading"
            block
            color="teal"
            class="white--text"
            @click="clearFilters()"
            >Clear Filters
          </v-btn>
        </v-col>
        <v-col cols="2">
          <v-btn
            block
            :loading="loading"
            :disabled="!validFilters"
            color="primary"
            @click="getReconciliationReport()"
            >Load Report
          </v-btn>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length">
        <v-col cols="12">
          <v-divider class="py-0 my-0" />
        </v-col>
        <v-col cols="12" v-if="reportSummary">
          <p class="text-h5">Invoice Summary</p>
          <p><b>Charged By Carrier:</b> £{{ reportSummary.totalCharges }}</p>
          <p><b>Billed To Customer:</b> £{{ reportSummary.totalBilled }}</p>
          <p
            :class="
              isProfit(reportSummary.totalProfitNum)
                ? 'green--text'
                : 'red--text'
            "
          >
            <b
              >{{
                isProfit(reportSummary.totalProfitNum) ? "Profit" : "Loss"
              }}:</b
            >
            £{{ reportSummary.totalProfitNum }}
          </p>
          <p
            :class="
              isProfit(reportSummary.totalProfitNum)
                ? 'green--text'
                : 'red--text'
            "
          >
            <b>GP:</b> {{ reportSummary.totalProfitPerc }}%
          </p>

          <p>
            <b>{{ reportSummary.positiveRows }} Rows with Profit:</b> £{{
              reportSummary.positiveRowsProfit
            }}
          </p>
          <p>
            <b>{{ reportSummary.negativeRows }} Rows with Loss:</b> £{{
              reportSummary.negativeRowsLoss
            }}
          </p>
        </v-col>
      </v-row>
      <v-row v-if="data && data.length">
        <v-col cols="12">
          <v-data-table
            class="elevation-2 my-2 header-highlighted"
            :headers="headers"
            :items="filteredReport"
            :items-per-page="10"
            :search="search"
            sort-by="margin_diff"
          >
            <template v-slot:top>
              <v-row class="px-4 pt-4">
                <v-col cols="3" class="pb-0 mb-0">
                  <ExportButtons
                    :headers="headers"
                    :data="filteredReport"
                    :title="'invoice_rec_report'"
                  />
                </v-col>
                <v-col cols="9" class="pb-0 mb-0">
                  <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Search"
                    single-line
                    hide-details
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
                <v-col cols="6" xl="4">
                  <v-checkbox
                    class="pr-5"
                    v-model="filterOutReconciled"
                    :label="`filter out data where expected margin achieved (${reconciledVariance}% variance)`"
                  />
                </v-col>
                <v-col cols="6" xl="4">
                  <div class="d-flex pa-0 ma-0">
                    <v-checkbox
                      class="pr-5"
                      v-model="useGpFilter"
                      label="filter by achieved margin (GP) range"
                    />

                    <v-range-slider
                      v-model="gpRangeFilter"
                      :max="maxGpRange"
                      :min="minGpRange"
                      step="1"
                      :disabled="!useGpFilter"
                      class="align-center mt-4"
                      :hint="`showing data where GP is between ${gpRangeFilter[0]}% and ${gpRangeFilter[1]}%`"
                    >
                      <template v-slot:thumb-label="props">
                        {{ props.value }}
                      </template>
                    </v-range-slider>
                  </div>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.order_number="{ item }">
              <v-btn
                v-if="item.order_number"
                small
                outlined
                @click="orderNumberPressed(item)"
                :loading="loading"
                >{{ item.order_number }}</v-btn
              >
              <v-btn
                v-else
                small
                outlined
                @click="orderNumberPressed(item)"
                :loading="loading"
                >No Order Number</v-btn
              >
            </template>
            <template v-slot:item.invoice_amount="{ item }">
              £{{ item.invoice_amount }}
            </template>

            <template v-slot:item.charges_from_prev_invoices="{ item }">
              £{{ item.charges_from_prev_invoices }}
            </template>

            <template v-slot:item.total_charges="{ item }">
              <v-chip outlined>£{{ item.total_charges }}</v-chip>
            </template>
            <template v-slot:item.billed="{ item }">
              <v-chip outlined>£{{ item.billed }}</v-chip>
            </template>
            <template v-slot:item.total_profit="{ item }">
              <v-chip outlined :class="statusColor(item.total_profit, 0)"
                >£{{ item.total_profit }}</v-chip
              >
            </template>
            <template v-slot:item.expected_margin="{ item }">
              <v-chip outlined v-if="item.expected_margin"
                >{{ item.expected_margin }}%</v-chip
              >
            </template>
            <template v-slot:item.GP_perc="{ item }">
              <v-chip outlined>{{ item.GP_perc }}%</v-chip>
            </template>
            <template v-slot:item.margin_diff="{ item }">
              <v-chip
                v-if="item.margin_diff"
                outlined
                :class="statusColor(item.margin_diff, 0)"
                >{{ item.margin_diff }}%</v-chip
              >
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <!-- detailed charges modal -->
      <v-dialog v-model="showDetailedCharges" max-width="90vw">
        <v-card class="pa-2">
          <v-row class="grey lighten-2 ma-0 pa-0">
            <v-col cols="7" class="ma-0 pa-0">
              <v-card-title class="text-h5">
                carrier charges for {{ chargesDescription }}
              </v-card-title>
            </v-col>
            <v-col cols="5" align="right">
              <ExportButtons
                :headers="chargesHeaders"
                :data="chargesData"
                :title="'carrier_charges_' + chargesDescription.substring(0,15)"
              />
            </v-col>
          </v-row>

          <v-data-table
            v-if="chargesData && chargesData.length"
            :headers="chargesHeaders"
            :items="chargesData"
            :items-per-page="5"
            :search="search"
            class="header-highlighted"
            ><template v-slot:item.created_at="{ item }">
              {{ formatDateString(item.created_at) }}
            </template>
          </v-data-table>

          <v-row
            class="grey lighten-2 ma-0 pa-0"
            v-if="billedData && billedData.length"
          >
            <v-col cols="7" class="ma-0 pa-0">
              <v-card-title class="text-h5">
                billed charges for {{ chargesDescription }}
              </v-card-title>
            </v-col>
            <v-col cols="5" align="right">
              <ExportButtons
                :headers="billedHeaders"
                :data="billedData"
                :title="'billed_charges_' + chargesDescription.substring(0,15)"
              />
            </v-col>
          </v-row>
          <v-data-table
            v-if="billedData && billedData.length"
            :headers="billedHeaders"
            :items="billedData"
            :items-per-page="5"
            :search="search"
            class="header-highlighted"
            ><template v-slot:item.charge_date="{ item }">
              {{ formatDateString(item.charge_date) }}
            </template>
          </v-data-table>
        </v-card>
      </v-dialog>
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="6000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import CommonFunctionsMixin from "@/mixins/BillingCommonFunctions";
import Carriers from "@/services/Carriers.js";
import ExportButtons from "@/components/ExportButtons";

import CompanySelect from "../components/CompanySelectUpdated";
import DatePicker from "../components/forms/DatePicker";

export default {
  components: { ExportButtons, CompanySelect, DatePicker },
  mixins: [CommonFunctionsMixin],
  props: {},
  data() {
    return {
      invoiceNumbers: [],
      selectedInvoiceNumber: "",
      carriers: [],
      selectedCarrier: null,
      selectedCompany: null,

      selectedDates: [],

      search: "",
      data: [],
      headers: [
        { text: "carrier", value: "carrier" },
        { text: "company code", value: "company_code" },
        { text: "invoice number", value: "invoice_number" },
        { text: "invoice date", value: "invoice_date" },
        { text: "order number", value: "order_number" },
        { text: "carrier references", value: "carrier_references" },
        { text: "invoice amount", value: "invoice_amount" },
        {
          text: "charges from prev invoices",
          value: "charges_from_prev_invoices",
        },
        { text: "previous invoices", value: "previous_invoices" },
        { text: "total charges", value: "total_charges" },
        { text: "billed", value: "billed" },
        { text: "total profit", value: "total_profit" },
        { text: "expected margin", value: "expected_margin" },
        { text: "achieved margin (GP)", value: "GP_perc" },
        { text: "margin difference", value: "margin_diff" },
      ],

      chargesData: [],
      chargesHeaders: [],
      showDetailedCharges: false,
      chargesDescription: "",

      billedData: [],
      billedHeaders: [],

      loading: false,
      text: "",
      snackbar: false,

      useGpFilter: false,
      gpRangeFilter: [],
      maxGpRange: 0,
      minGpRange: 0,

      filterOutReconciled: true,
      reconciledVariance: 2,
    };
  },
  computed: {
    validFilters() {
      if (this.selectedCarrier) return true;
      if (this.selectedInvoiceNumber) return true;
      if (this.selectedDates.length === 2) return true;
      return false;
    },
    reportSummary() {
      if (!this.data || this.data.length < 1) return null;
      let totalCharges = 0;
      let totalBilled = 0;
      let totalProfitNum = 0;
      let positiveRows = 0;
      let positiveRowsProfit = 0;
      let negativeRows = 0;
      let negativeRowsLoss = 0;
      for (const row of this.data) {
        const rowCharge = parseFloat(row.total_charges);
        const rowBilled = parseFloat(row.billed);
        const rowProfit = parseFloat(row.total_profit);

        totalCharges += rowCharge;
        totalBilled += rowBilled;
        totalProfitNum += rowProfit;

        if (this.isProfit(rowProfit)) {
          positiveRows++;
          positiveRowsProfit += rowProfit;
        } else {
          negativeRows++;
          negativeRowsLoss += rowProfit;
        }
      }

      const totalProfitPerc = (totalProfitNum * 100) / totalCharges;

      return {
        totalCharges: this.roundDecimal(totalCharges, 2),
        totalBilled: this.roundDecimal(totalBilled, 2),
        totalProfitNum: this.roundDecimal(totalProfitNum, 2),
        totalProfitPerc: this.roundDecimal(totalProfitPerc, 2),
        positiveRows: positiveRows,
        positiveRowsProfit: this.roundDecimal(positiveRowsProfit, 2),
        negativeRows: negativeRows,
        negativeRowsLoss: this.roundDecimal(negativeRowsLoss, 2),
      };
    },

    filteredReport() {
      let filtered = this.data;
      if (!this.useGpFilter && !this.filterOutReconciled) return filtered;

      if (this.filterOutReconciled) {
        filtered = filtered.filter((item) => {
          return this.filterMargin(item);
        });
      }

      if (this.useGpFilter && this.gpRangeFilter.length === 2) {
        filtered = filtered.filter((item) => {
          return this.filterGpRange(item);
        });
      }

      return filtered;
    },
    // only show carriers  that actually have invoices uploaded
    // we do this by looking at invoiceNumbers, loop through all of them to get all carrierIds for them
    filteredCarriers(){
      if(!this.carriers) return []
      if(!this.invoiceNumbers || this.invoiceNumbers.length < 1){
        return this.carriers
      }9
      let carrierIds = this.invoiceNumbers.map(obj => parseInt(obj.carrier_id));
      return this.carriers.filter(obj => carrierIds.includes(parseInt(obj.id)));

    },
    // if carrier is selected, only show invoice numbers for that carrier, otherwise show all invoice numbers
    filteredInvoiceNumbers(){
      const carrierId = this.selectedCarrier
      if(!carrierId){
        return this.invoiceNumbers
      }
      if(!this.invoiceNumbers) return []

      return this.invoiceNumbers.filter( el => el.carrier_id == carrierId)


    },
  },
  methods: {
    filterGpRange(item) {
      const gpPerc = item.GP_perc;
      if (!gpPerc) return true;
      return gpPerc >= this.gpRangeFilter[0] && gpPerc <= this.gpRangeFilter[1];
    },
    filterMargin(item) {
      const variance = this.reconciledVariance;
      const margin = parseFloat(item.expected_margin);
      if (!margin) return true;
      const gpPerc = parseFloat(item.GP_perc);
      if (!gpPerc) return true;

      const difference = Math.abs(Math.abs(margin) - Math.abs(gpPerc));
      return difference >= variance;
    },
    roundDecimal(number, decimalPlaces = 2) {
      const multiplier = 10 ** decimalPlaces;
      const rounded = Math.round(number * multiplier) / multiplier;
      return rounded ? rounded : 0;
    },
    setDateRange(dates) {
      this.selectedDates = dates;
    },
    setCompany(company) {
      this.selectedCompany = company;
    },

    isProfit(val, threshold = 0) {
      return val >= threshold;
    },
    statusColor(value, threshold) {
      if (threshold === null || threshold === "") return "";
      if (value === null || value === "") return "";
      if (this.isProfit(value, threshold)) return "green--text green";
      return "red--text red";
    },
    async orderNumberPressed(row) {
      const orderNumber = row.order_number;
      this.loading = true
      if (orderNumber) {
        this.chargesDescription = orderNumber;
        await this.getStandardisedCharges(orderNumber);
        await this.getBilledCharges(orderNumber);
      } else {
        this.chargesDescription = " invoice without an order number";
        this.billedData = [];
        await this.getStandardisedCharges("null", row.invoice_number);
      }
      this.loading = false
      this.showDetailedCharges = true;
    },
    carrierSelected(ev) {
      this.getInvoicesList(ev);
    },
    clearFilters() {
      this.selectedCarrier = null;
      this.selectedInvoiceNumber = null;
      this.selectedCompany = null;
      this.selectedCompanyCode = null;
      this.selectedDates = [];
      this.getInvoicesList(null);
    },
    async getCarriers() {
      return Carriers.getCarriers().then((carriers) => {
        this.carriers = carriers;
      });
    },
    async getInvoicesList(carrierId = null) {
      this.loading = true;
      Carriers.getInvoicesList(carrierId)
        .then((response) => {
          this.invoiceNumbers = response.data;
    
        })
        .catch((error) => {
          const text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.activateSnackbar(text);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async loadInitialData() {
      // load invoice numbers for dropdown
      this.getCarriers();
      this.getInvoicesList();
    },
    async getStandardisedCharges(orderNumber, invoiceNumber = null) {
      this.chargesData = [];
      return Carriers.getStandardisedCharges(orderNumber, invoiceNumber)
        .then((response) => {
          this.chargesData = response.data;
          this.chargesHeaders = this.getHeaders(response.data, [
            "id",
            "carrier_id",
            "customer_ref_2",
            "created_at",
            "updated_at",
          ]);
        })
        .catch((error) => {
          const text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.activateSnackbar(text);
        })
        .finally(() => {
          return
        });
    },
    async getBilledCharges(orderNumber) {
      this.billedData = [];
      return Carriers.getBilledCharges(orderNumber)
        .then((response) => {
          this.billedData = response.data;
          this.billedHeaders = this.getHeaders(response.data, ["tariff"]);
        })
        .catch((error) => {
          const text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.activateSnackbar(text);
        })
        .finally(() => {
          return
        });
    },
    setFilters(data) {
      let max = 0;
      let min = 0;
      for (const row of data) {
        const gpPerc = parseFloat(row.GP_perc);
        if (!gpPerc) continue;
        const ceiledVal = Math.ceil(gpPerc);
        const flooredVal = Math.floor(gpPerc);
        if (ceiledVal > max) {
          max = ceiledVal;
        }
        if (flooredVal < min) {
          min = flooredVal;
        }
      }
      this.minGpRange = min;
      this.maxGpRange = max;
      this.gpRangeFilter = [min, max];
    },
    calculateMarginDifference(data) {
      for (const row of data) {
        const margin = parseFloat(row.expected_margin);
        const gp = parseFloat(row.GP_perc);
        if (margin && gp) {
          row.margin_diff = this.roundDecimal(gp - margin, 2);
        } else {
          row.margin_diff = null;
        }
      }
      return data;
    },
    async getReconciliationReport() {
      this.loading = true;
      this.data = [];
      let dateFrom = null;
      let dateTo = null;
      if (this.selectedDates.length === 2) {
        dateFrom = this.selectedDates[0];
        dateTo = this.selectedDates[1];
      }
      const companyId = this.selectedCompany ? this.selectedCompany.id : null;
      Carriers.getReconciliationReport(
        this.selectedInvoiceNumber,
        this.selectedCarrier,
        companyId,
        dateFrom,
        dateTo
      )
        .then((response) => {
          this.data = this.calculateMarginDifference(response.data);
          this.setFilters(response.data);
          this.activateSnackbar("loaded report");
        })
        .catch((error) => {
          const text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.activateSnackbar(text);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    activateSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
  },

  created() {
    this.loadInitialData();
  },
};
</script>

<style>
.header-highlighted th {
  font-weight: bold;
  color: black !important;
}
</style>