import * as XLSX from 'xlsx';

const InvoiceRec = {
  methods: {
    mapCarrierExtraction(parsedFile, fileName) {
      const sheetNames = parsedFile.SheetNames;
      const evriRequiredSheets = [
        'Rates and Summary',
        'Delivery Volume',
        '16 Digit Returns',
        'Chargeable Events',
        '12 Digit Net',
        'C2B Volume',
        'SMS',
      ];

      // Evri Int
      if (sheetNames.length === 3 && sheetNames[1] === 'Shipments' ) {
        const formatted = this.formatEvriIntCharges(parsedFile, fileName);
        return formatted;
      } 
      if(sheetNames.length === 2 && sheetNames[1] === 'Undeliverables'){
        const formatted = this.formatEvriIntUndeliverables(parsedFile, fileName);
        return formatted;
      }
       // evri
      else if (
        evriRequiredSheets.every((item) => sheetNames.includes(item) || (sheetNames.length < 3 && sheetNames[0] === 'Delivery Volume'))
      ) {
        const formatted = this.formatEvriCharges(parsedFile, fileName);

        return formatted;
      } 
      // DPD
      else if(sheetNames.length === 1 && sheetNames[0] === 'Sheet1' 
      && parsedFile.Sheets.Sheet1.A1.v === 'Account No'
      && parsedFile.Sheets.Sheet1.A2.v === 'Nett Invoice Value'
      && parsedFile.Sheets.Sheet1.A3.v === 'VAT'
      && parsedFile.Sheets.Sheet1.A4.v === 'Gross Invoice Value'){
        const formatted = this.formatDpdCharges(parsedFile, fileName);

        return formatted;
      }
      else {
        throw new Error('unrecognised file');
      }
    },
    getDpdInvoiceDate(endingDate) {
      // this function will find the date for the friday for the same week of the date passed
      if (!endingDate) return null;
      let date = new Date(this.formatDate(endingDate));

      const dayOfWeek = date.getDay();
      let daysToAdd = 0;
      switch (dayOfWeek) {
        case 0:
        case 1:
        case 2:
        case 3:
        case 4:
          daysToAdd = 5 - dayOfWeek;
          break;
        case 6:
          daysToAdd = -1;
          break;
      }
      date.setDate(date.getDate() + daysToAdd);
      // return dateWithOffset instead of date to prevent daylight savings issue
      let timezoneOffset = date.getTimezoneOffset() * 60000
      let dateWithOffset = new Date(date.getTime() + (timezoneOffset * -1))

      return dateWithOffset;
    
    },
    selectDpdOrDpdLocal(sheetArr){
      const accNumber =  sheetArr[0][1]
      const carrierName = accNumber == '2124991' ? 'dpd local' : 'dpd'
      return this.carriers.find(
        (carrier) => carrier.name.toLowerCase() === carrierName
      );
    },
    formatDpdCharges(parsedFile, fileName){
      const sheetName = 'Sheet1';
      const sheet = parsedFile.Sheets[sheetName]
      const sheetArr = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: true,
      });

      const carrier = this.selectDpdOrDpdLocal(sheetArr)
      const invoiceNumber = sheetArr[0][3] //parsedFile.Sheets[sheetName]['D1'].v;
      const invoiceDate = this.getDpdInvoiceDate(sheetArr[5][0])
   
      const invoiceDateStr = this.formatDate(invoiceDate);
      if (!invoiceNumber)
        throw new Error('Invoice Number could not be extracted');

      const formatted = this.dpdToStandardised(
        sheetArr,
        invoiceNumber,
        invoiceDateStr,
        fileName,
        sheetName,
        carrier.id
      );

      this.generateSummary(fileName, carrier, invoiceNumber, invoiceDate, sheetName, formatted)

      return formatted;
    },
    generateSummary(fileName, carrier, invoiceNumber, invoiceDate, sheetName, formatted, sheetsSummary = null){
      const summary = {
        fileName: fileName,
        carrier: carrier,
        invoiceNumber: invoiceNumber,
        invoiceDate: invoiceDate,
        sheets: sheetsSummary ? sheetsSummary : [
          {
            name: sheetName,
            numRows: formatted.length,
            totalChargeValue: this.roundDecimal(
              this.sumColumn(formatted, 'invoice_amount')
            ),
          },
        ],
      };
      this.summaryReadFiles.push(summary);
    },

    dpdToStandardised(
      sheetArr,
      invoiceNumber,
      invoiceDateStr,
      fileName,
      sheetName,
      carrierId
    ) {

      
      let rows = sheetArr.slice(5, sheetArr.length);
      // filter empty rows
      rows = rows.filter( row => row.length > 0)
      const groupedByConsignment = this.groupByElement(rows, 1)
      const charges = this.calculateDpdChargesForSameConsignment(groupedByConsignment)

      const formatted = charges.map((row) => {
        let weightDesc = row[12] ? this.roundDecimal(row[12],2) + 'Kg' : ''
        let parcelsDesc = row[13] ? row[13] : ''
        let description = `${row[7]} - ${row[10] ?? ''} - ${weightDesc} - ${parcelsDesc}`

        return {
          from_file: fileName,
          from_sheet: sheetName,
          carrier_id: carrierId, // TO DO - GET carrier and assign carrier id
          invoice_number: invoiceNumber,
          invoice_date: invoiceDateStr,
          transaction_date: this.formatDate(row[0]),
          description:description,
          carrier_ref_1: row[1],
          carrier_ref_2: row[3],
          'customer_ref_1': row[11],
          'customer_ref_2': row[37],
          invoice_amount: this.calculateDpdAmount(row),
        };
      });
      return formatted;
    },
    calculateDpdChargesForSameConsignment(grouped){
      let parsed = []
      for(const consignmentRef in grouped){
          let rows = grouped[consignmentRef]
          let numberParcels = rows.length
          if(numberParcels > 1){
              for(let i=12; i < 35;i++){
                  if(i === 14 || i === 16) continue
                  let found = rows.find(el => {
                      let col = el[i]
                      if (typeof col === 'number' ) {
                          return true;
                        }else{
                          return false
                        }
                  })
                  if(found){
                      let val = found[i]
                      rows.map( el => {
                          el[i] = val / numberParcels
                      })
                  }
  
              }
          }
          parsed = parsed.concat(rows)
      }
      return parsed
  },
  
  
  groupByElement(arr, index) {
      return arr.reduce((groups, currentArray) => {
        const key = currentArray[index];
        if (!groups[key]) {
          groups[key] = [];
        }
        groups[key].push(currentArray);
        return groups;
      }, {});
  },
    

    calculateDpdAmount(row){
      let cost = 0
      for(let i=15;i<35; i++){
        let cellNumValue = parseFloat(row[i])
        if(cellNumValue){
          cost += cellNumValue
        }
      }
      // add 20% VAT
      return this.roundDecimal(cost)
    },
    getInvoiceNFromEvriFile(parsedFile) {
      let invoiceNumber = parsedFile.Sheets['Delivery Volume']['G2'].v;
      // try next sheet
      if (!invoiceNumber && parsedFile.Sheets['16 Digit Returns']) {
        invoiceNumber = parsedFile.Sheets['16 Digit Returns']['G2'].v;
      }
      return invoiceNumber;
    },
    getDateFromYearAndWeek(year, week) {
      const date = new Date(year, 0, 1 + (week - 1) * 7);
      const day = date.getDate();
      const month = date.getMonth() + 1; // Note: January is month 0
      const formattedMonth = month < 10 ? `0${month}` : month;
      const formattedDay = day < 10 ? `0${day}` : day;
    
      return `${year}-${formattedMonth}-${formattedDay}`;
    },
    generateInvoiceDateEvri(invoiceNumber){
      const year = invoiceNumber.slice(0, 4);
      const week = parseInt(invoiceNumber.slice(4), 10);

      return this.getDateFromYearAndWeek(year, week);
    },
    formatEvriCharges(parsedFile, fileName) {
      const carrier = this.carriers.find(
        (carrier) => carrier.name.toLowerCase() === 'hermes'
      );
      const carrierId = carrier.id;
      
      const sheetsToRead = parsedFile.SheetNames.length < 3 ? ['Delivery Volume'] : [
        'Delivery Volume',
        '16 Digit Returns',
        'Chargeable Events',
        '12 Digit Net',
        'C2B Volume',
        'SMS',
      ];
      let sheetsSummary = [];
      let standardisedData = [];
      const invoiceNumber = this.getInvoiceNFromEvriFile(parsedFile);
      if (!invoiceNumber) throw new Error('Could not extract invoice number');

      const invoiceDate = this.generateInvoiceDateEvri(invoiceNumber);
      

      for (const sheetName of sheetsToRead) {
        const sheetArr = XLSX.utils.sheet_to_json(
          parsedFile.Sheets[sheetName],
          {
            header: 1,
            raw: true,
          }
        );
        let toStandardised = [];
        switch (sheetName) {
          case 'Delivery Volume':
            // evri invoices can be 2 types of files.
            // when the invoice only contains the delivery volume sheet to read, the charge must be calculated differently
            if(sheetsToRead.length === 1){
              toStandardised = this.evriExtrasToStandardised(sheetArr,
                fileName,
                sheetName,
                carrierId,
                invoiceNumber,
                invoiceDate,
                true)
            }else{
              toStandardised = this.evriToStandardised(
                sheetArr,
                fileName,
                sheetName,
                carrierId,
                invoiceNumber,
                invoiceDate,
                5,
                21,
                4,
                null,
                8,
                9,
                22,
                true
              );
            }
          
            break;
          case '16 Digit Returns':
            toStandardised = this.evriToStandardised(
              sheetArr,
              fileName,
              sheetName,
              carrierId,
              invoiceNumber,
              invoiceDate,
              5,
              12,
              4,
              null,
              null,
              null,
              13,
              true
            );
            break;
          case 'Chargeable Events':
            toStandardised = this.evriEventsToStandardised(
              sheetArr,
              fileName,
              sheetName,
              carrierId,
              invoiceNumber,
              invoiceDate
            );
            break;
          case '12 Digit Net':
            toStandardised = this.evriToStandardised(
              sheetArr,
              fileName,
              sheetName,
              carrierId,
              invoiceNumber,
              invoiceDate,
              3,
              10,
              2,
              null,
              6,
              null,
              11
            );
            break;
          case 'C2B Volume':
            // sheet needs to be mapped, but no invoice available as example, so
            // if an invoice happens to have this sheet populated, warn user to contact dev
            if (sheetArr.length)
              throw new Error(
                'C2B Volume populated, contact dev team to map this sheet'
              );
            break;
          case 'SMS':
            toStandardised = this.evriToStandardised(
              sheetArr,
              fileName,
              sheetName,
              carrierId,
              invoiceNumber,
              invoiceDate,
              4,
              10,
              3,
              null,
              7,
              8,
              11
            );
            break;
        }

        standardisedData = standardisedData.concat(toStandardised);
        sheetsSummary.push({
          name: sheetName,
          numRows: toStandardised.length,
          totalChargeValue: this.roundDecimal(
            this.sumColumn(toStandardised, 'invoice_amount')
          ),
        });
      }

      this.generateSummary(fileName, carrier, invoiceNumber, invoiceDate, null, null, sheetsSummary)

      return standardisedData;
    },

    

    evriToStandardised(
      sheetArr,
      fileName,
      sheetName,
      carrierId,
      invoiceNumber,
      invoiceDate,
      tDateIndex,
      descriptionIndex,
      carrierRef1Index,
      carrierRef2Index,
      plRef1Index,
      plRef2Index,
      invoiceAmIndex,
      removeHeader = true
    ) {
      const rows = removeHeader ? sheetArr.slice(1, sheetArr.length) : sheetArr;

      const formatted = rows.map((row) => {
        const tDate = this.formatDate(row[tDateIndex]);
        return {
          from_file: fileName,
          from_sheet: sheetName,
          carrier_id: carrierId,
          invoice_number: invoiceNumber,
          invoice_date: invoiceDate,
          transaction_date: tDate,
          description: row[descriptionIndex],
          carrier_ref_1: row[carrierRef1Index],
          carrier_ref_2: carrierRef2Index ? row[carrierRef2Index] : '',
          'customer_ref_1': plRef1Index ? row[plRef1Index] : '',
          'customer_ref_2': plRef2Index ? row[plRef2Index] : '',
          invoice_amount: this.roundDecimal(row[invoiceAmIndex]),
        };
      });
      return formatted;
    },

    evriExtrasToStandardised(
      sheetArr,
      fileName,
      sheetName,
      carrierId,
      invoiceNumber,
      invoiceDate,
      removeHeader
    ) {
      const rows = removeHeader ? sheetArr.slice(1, sheetArr.length) : sheetArr;

      const formatted = rows.map((row) => {
        const tDate = this.formatDate(row[5]);
        let totalCharge = this.roundDecimal(row[25] + row[28])
        return {
          from_file: fileName,
          from_sheet: sheetName,
          carrier_id: carrierId,
          invoice_number: invoiceNumber,
          invoice_date: invoiceDate,
          transaction_date: tDate,
          description: `${row[21]} - ${row[26]}`,
          carrier_ref_1: row[4],
          carrier_ref_2: '',
          'customer_ref_1': row[8], 
          'customer_ref_2': row[9], 
          invoice_amount: totalCharge,
        };
      });
      return formatted;
    },

    roundDecimal(number, decimalPlaces = 2) {
      const multiplier = 10 ** decimalPlaces;
      const rounded =  Math.round(number * multiplier) / multiplier;
      return rounded ? rounded : 0
    },

    evriEventsToStandardised(
      sheetArr,
      fileName,
      sheetName,
      carrierId,
      invoiceNumber,
      invoiceDate
    ) {
      const rows = sheetArr.slice(1, sheetArr.length);
      const formatted = rows.map((row) => {
        const tDate = this.formatDate(row[6]);
        return {
          from_file: fileName,
          from_sheet: sheetName,
          carrier_id: carrierId,
          invoice_number: invoiceNumber,
          invoice_date: invoiceDate,
          transaction_date: tDate,
          description: `${row[15]} - ${row[13]}`,
          carrier_ref_1: row[4],
          carrier_ref_2: row[5],
          'customer_ref_1': row[9],
          'customer_ref_2': row[10],
          invoice_amount: this.roundDecimal(row[16]),
        };
      });
      return formatted;
    },

    findIndexFromEnd(arr, condition) {
      for (let i = arr.length - 1; i >= 0; i--) {
        if (condition(arr[i])) {
          return i;
        }
      }
      return -1; // Condition was not met for any element
    },
    extractParcelNumFromFormula(formula) {
      if (formula.startsWith('HYPERLINK')) {
        const endIdx = formula.lastIndexOf('"');
        if (endIdx > 0) {
          const startIdx = formula.lastIndexOf('"', endIdx - 1) + 1;
          if (startIdx >= 0 && startIdx < endIdx) {
            const extractedValue = formula.substring(startIdx, endIdx);
            return extractedValue;
          }
        }
      }
      return formula;
    },

    formatDate(date) {
      if (!(date instanceof Date)) {
        // try to convert strings of format dd/mm/yyyy
        if (date) {
          const parts = date.split('/');
          if (parts.length === 3) {
            const [day, month, year] = parts;
            return `${year.length === 2 ? '20' + year : year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
          }
        }
        return '';
      }
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed
      const day = String(date.getDate()).padStart(2, '0');

      return `${year}-${month}-${day}`;
    },
    sumColumn(arr, columnIndex) {
      return arr.reduce((total, currentArray) => {
        return total + currentArray[columnIndex];
      }, 0);
    },

    formatEvriIntRows(sheetArr, invoiceNumber, invoiceDateStr) {
      // last row of data should be the totals, which should be removed from the data to be uploaded
      const condition = (element) => element[0] === 'Total';
      const lastIndex = this.findIndexFromEnd(sheetArr, condition);
      if (lastIndex === -1) return [];

      const rows = sheetArr.slice(5, lastIndex - 1);

      const formatted = rows.map((row) => {
        return {
          invoice_number: invoiceNumber,
          invoice_date: invoiceDateStr,
          parcel_number: row[0],
          hub: row[1],
          delivery_date: this.formatDate(row[2]),
          customer_ref1: row[3],
          customer_ref2: row[4],
          sub_account: row[5],
          service: row[6],
          ddp: row[7],
          description: row[8],
          length: row[9],
          width: row[10],
          height: row[11],
          declared: row[12],
          actual: row[13],
          volumetric: row[14],
          amount: row[15],
          currency: row[16],
          country: row[17],
          code: row[18],
          state: row[19],
          town: row[20],
          postcode: row[21],
          shipping: row[22],
          shipping_vat: row[23],
          surcharges: row[24],
          surcharges_vat: row[25],
          total_net: row[26],
          total_vat: row[27],
        };
      });
      return formatted;
    },

    evriIntToStandardised(
      sheetArr,
      invoiceNumber,
      invoiceDateStr,
      fileName,
      sheetName,
      carrierId
    ) {
      const condition = (element) => element[0] === 'Total';
      const lastIndex = this.findIndexFromEnd(sheetArr, condition);
      if (lastIndex === -1) return [];

      const rows = sheetArr.slice(4, lastIndex);

      const formatted = rows.map((row) => {
        return {
          from_file: fileName,
          from_sheet: sheetName,
          carrier_id: carrierId, // TO DO - GET carrier and assign carrier id
          invoice_number: invoiceNumber,
          invoice_date: invoiceDateStr,
          transaction_date: this.formatDate(row[2]),
          description: row[8],
          carrier_ref_1: row[0],
          'customer_ref_1': row[3],
          'customer_ref_2': row[4],
          invoice_amount: this.roundDecimal(row[26]),
        };
      });
      return formatted;
    },

    formatEvriIntCharges(parsedFile, fileName) {
      const carrier = this.carriers.find(
        (carrier) => carrier.name.toLowerCase() === 'hermes international'
      );

      const sheetName = 'Shipments';
      const sheet = this.parseFormulasOfSheet(
        parsedFile.Sheets[sheetName],
        this.extractParcelNumFromFormula
      );
      const sheetArr = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: true,
      });

      // get invoice number and invoice date from summary sheet
      const invoiceNumber = parsedFile.Sheets['Summary']['D9'].v;
      const invoiceDate = parsedFile.Sheets['Summary']['D7'].v;
      const invoiceDateStr = this.formatDate(invoiceDate);
      if (!invoiceNumber)
        throw new Error('Invoice Number could not be extracted');

      const formatted = this.evriIntToStandardised(
        sheetArr,
        invoiceNumber,
        invoiceDateStr,
        fileName,
        sheetName,
        carrier.id
      );

      this.generateSummary(fileName, carrier, invoiceNumber, invoiceDate, sheetName, formatted)

      return formatted;
    },
    formatEvriIntUndeliverables(parsedFile, fileName) {
      const carrier = this.carriers.find(
        (carrier) => carrier.name.toLowerCase() === 'hermes international'
      );

      const sheetName = 'Undeliverables';
      const sheet = this.parseFormulasOfSheet(
        parsedFile.Sheets[sheetName],
        this.extractParcelNumFromFormula
      );
      const sheetArr = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        raw: true,
      });

      // get invoice number and invoice date from summary sheet
      const invoiceNumber = parsedFile.Sheets['Summary']['D9'].v;
      const invoiceDate = parsedFile.Sheets['Summary']['D7'].v;
      const invoiceDateStr = this.formatDate(invoiceDate);
      if (!invoiceNumber)
        throw new Error('Invoice Number could not be extracted');

      const formatted = this.evriIntUndelToStandardised(
        sheetArr,
        invoiceNumber,
        invoiceDateStr,
        fileName,
        sheetName,
        carrier.id
      );

      this.generateSummary(fileName, carrier, invoiceNumber, invoiceDate, sheetName, formatted)

      return formatted;
    },

    evriIntUndelToStandardised(
      sheetArr,
      invoiceNumber,
      invoiceDateStr,
      fileName,
      sheetName,
      carrierId
    ) {
      const condition = (element) => element[0] === 'Total';
      const lastIndex = this.findIndexFromEnd(sheetArr, condition);
      if (lastIndex === -1) return [];

      const rows = sheetArr.slice(4, lastIndex);

      const formatted = rows.map((row) => {
        return {
          from_file: fileName,
          from_sheet: sheetName,
          carrier_id: carrierId, // TO DO - GET carrier and assign carrier id
          invoice_number: invoiceNumber,
          invoice_date: invoiceDateStr,
          transaction_date: this.formatDate(row[2]),
          description: row[9],
          carrier_ref_1: row[0],
          'customer_ref_1': row[5],
          'customer_ref_2': row[6],
          invoice_amount: this.roundDecimal(row[22]),
        };
      });
      return formatted;
    },

    getAllInvoiceCombinations(standardisedCharges) {
      let combinations = [];
    
      for (const charge of standardisedCharges) {
        const alreadyExists = combinations.find((el) => {
          return (
            el.carrier_id === charge.carrier_id &&
            el.invoice_number === charge.invoice_number
          );
        });
        if (!alreadyExists) {
          const obj = {
            carrier_id: charge.carrier_id,
            invoice_number: charge.invoice_number,
          };
          combinations.push(obj);
        }
      }
      return combinations;
    }
  },
};

export default InvoiceRec;
