<template>
  <div class="billingUpload">
    <div class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title is-size-1">Invoice Reconciliation</h1>
        </div>
      </div>
    </div>

    <!-- show old method of invoice rec -->
    <v-row>
      <v-col cols="9"></v-col>
      <v-col cols="3">
        <v-btn icon @click="showLegacy = !showLegacy">
          <v-icon>{{
            showLegacy ? "mdi-chevron-up" : "mdi-chevron-down"
          }}</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <div class="container" v-show="showLegacy">
        <v-form v-model="valid" class="ma-2 pa-4" @submit.prevent>
          <v-card :loading="loading" class="mb-4">
            <div class="pa-5">
              <h1>Upload Charges</h1>
              <v-row>
                <v-col align-self="center" md="3">
                  <v-select
                    v-model="selectedCarrier"
                    :hint="`${selectedCarrier.value}, ${selectedCarrier.title}`"
                    :items="carriersList"
                    item-text="title"
                    item-value="value"
                    label="Select a Carrier"
                    outlined
                    :disabled="loading"
                    return-object
                    @change="resetForm"
                  >
                  </v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="4">
                  <v-card class="pa-4">
                    <h2 class="mb-2">Upload Invoice Charges</h2>
                    <v-file-input
                      class="mb-2"
                      show-size
                      counter
                      multiple
                      dense
                      outlined
                      small-chips
                      prepend-icon=""
                      prepend-inner-icon="attach_file"
                      label="File input"
                      @change="handleFileUploadInvoice"
                    ></v-file-input>
                    <v-btn
                      id="invoice-btn"
                      class="mb-2"
                      block
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      @click="uploadInvoiceFile()"
                      >{{ invoiceBtnTxt }}
                    </v-btn>
                  </v-card>
                </v-col>

                <v-col md="4">
                  <v-card class="pa-4">
                    <h2 class="mb-2">Upload Additional Charges</h2>
                    <v-file-input
                      class="mb-2"
                      show-size
                      counter
                      multiple
                      dense
                      small-chips
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="attach_file"
                      label="File input"
                      @change="handleFileUploadCharges"
                    ></v-file-input>
                    <v-btn
                      id="additional-btn"
                      class="mb-2"
                      :loading="loading"
                      :disabled="loading"
                      color="primary"
                      block
                      @click="uploadAdditionalChargesFile()"
                      >{{ additionalBtnTxt }}
                    </v-btn>
                  </v-card>
                </v-col>

                <v-col md="4">
                  <v-card class="pa-4">
                    <h2 class="mb-2">Upload Returns Charges</h2>

                    <v-file-input
                      class="mb-2"
                      show-size
                      counter
                      multiple
                      dense
                      small-chips
                      outlined
                      prepend-icon=""
                      prepend-inner-icon="attach_file"
                      label="File input"
                      @change="handleFileUploadReturns"
                    ></v-file-input>
                    <v-btn
                      id="returns-btn"
                      class="mb-2"
                      :loading="loading"
                      :disabled="loading"
                      block
                      color="primary"
                      @click="uploadReturnsChargesFile()"
                      >{{ returnsBtnTxt }}
                    </v-btn>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-card>
          <!-- Start of Invoice Report Part -->
          <v-card :loading="loading">
            <div class="pa-3">
              <v-row class="pa-4">
                <h2 v-if="selectedInvoiceWeek">
                  Invoice Report For {{ selectedCarrier.title }} Week
                  {{ selectedInvoiceWeek }}
                </h2>
                <h2 v-else>Invoice Report (Please Select a Week)</h2>
              </v-row>
              <v-col align-self="center" md="3">
                <v-select
                  v-model="selectedInvoiceWeek"
                  :items="invoiceWeeks"
                  item-text="week"
                  item-value="week"
                  label="Select a Week"
                  outlined
                  :disabled="loading"
                >
                </v-select>
              </v-col>
              <v-col md="3">
                <v-btn
                  block
                  :loading="loading"
                  :disabled="loading"
                  color="primary"
                  @click="loadInvoiceReport()"
                  >Load Invoice Report
                </v-btn>
              </v-col>
              <div v-if="reportData.length > 0">
                <v-col md="3">
                  <ExportButtons
                    :headers="headers"
                    :data="reportData"
                    :title="exportFileName"
                  />
                </v-col>
                <v-col md="12" class="ml-2">
                  <v-row>
                    <v-card class="ma-1" md="2">
                      <v-card-text class="font-weight-bold"
                        >Total Carrier Cost {{ totalCarrierCost }}
                      </v-card-text>
                    </v-card>
                    <v-card class="ma-1" md="2">
                      <v-card-text class="font-weight-bold"
                        >Total Label Cost {{ totalLabelCost }}
                      </v-card-text>
                    </v-card>
                    <v-card class="ma-1" md="2">
                      <v-card-text class="font-weight-bold"
                        >Total Cost {{ totalCost }}
                      </v-card-text>
                    </v-card>
                    <v-card class="ma-1" md="2">
                      <v-card-text class="font-weight-bold"
                        >Total Revenue {{ totalRevenue }}
                      </v-card-text>
                    </v-card>
                    <v-card class="ma-1" md="2">
                      <v-card-text class="font-weight-bold"
                        >Total Markup {{ totalMarkup }}
                      </v-card-text>
                    </v-card>
                    <v-card class="ma-1" md="2">
                      <v-card-text class="font-weight-bold"
                        >Total Margin {{ totalMargin }}%
                      </v-card-text>
                    </v-card>
                  </v-row>
                </v-col>

                <v-col md="12">
                  <v-data-table
                    class="elevation-1"
                    :headers="headers"
                    :items="reportData"
                    :items-per-page="10"
                    :search="search"
                    id="data-table"
                  >
                  </v-data-table>
                </v-col>
              </div>
            </div>
          </v-card>
        </v-form>
        <div class="ma-2 pa-4">
          <v-card :loading="shipmentInvoiceUploading" class="mb-4">
            <div class="pa-5">
              <h1>Upload Shipments from Invoice</h1>
              <v-row class="pa-4">
                <v-col align-self="center" md="4">
                  <v-select
                    v-model="shipmentCarrier"
                    :hint="`${shipmentCarrier.value}, ${shipmentCarrier.title}`"
                    :items="shipmentCarrierList"
                    item-text="title"
                    item-value="value"
                    label="Select a Carrier"
                    outlined
                    :disabled="shipmentInvoiceUploading"
                  >
                  </v-select>
                  <v-file-input
                    class="mb-2"
                    show-size
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="attach_file"
                    label="Shipments Invoice"
                    :disabled="shipmentInvoiceUploading"
                    ref="shipmentInvoiceInput"
                    @change="handleShipmentInvoiceFile"
                  ></v-file-input>
                  <v-btn
                    id="invoice-btn"
                    class="mb-2"
                    block
                    :loading="shipmentInvoiceUploading"
                    :disabled="shipmentInvoiceUploading"
                    color="primary"
                    @click="uploadShipmentInvoiceFile()"
                    >Upload Shipments
                  </v-btn>
                </v-col>
              </v-row>
            </div>
          </v-card>
        </div>
      </div>
    </v-expand-transition>

    <!-- generate standardised charges from carrier invoices -->
    <div class="my-2 py-4 mx-16">
      <v-card :loading="loading" class="mb-4 px-5 pb-5">
        <h1 class="pt-5 text-h4">
          Generate Standardised Charges from Carrier Invoices
        </h1>
        <v-divider />
        <v-row>
          <v-col cols="5">
            <v-file-input
              show-size
              counter
              multiple
              dense
              outlined
              small-chips
              :loading="loading"
              prepend-inner-icon="attach_file"
              label="Input Files"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xlsb"
              @change="handleInvoiceCarrierFiles"
              ref="fileInput"
            ></v-file-input>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="filePassword"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showPassword ? 'text' : 'password'"
              label="File Password"
              hint=""
              counter
              @click:append="showPassword = !showPassword"
              outlined
              dense
              :loading="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="3" align="right">
            <v-icon
              :color="showInfo ? 'error' : 'primary'"
              @click="showInfo = !showInfo"
              class="ma-2"
            >
              mdi-alert-circle
            </v-icon>
          </v-col>

          <v-col cols="12">
            <v-expand-transition>
              <div v-show="showInfo">
                <ul>
                  <li>
                    - Password field can also be ignored if files submitted do
                    not require a password to unlock.
                  </li>
                  <li>
                    - Please note, multiple invoices can be submited at a time,
                    however only 1 password can be used at the same time to
                    unlock files, so if there's multiple files which require
                    different passwords, then do not submit them together.
                  </li>
                  <li>
                    - <b>Evri Invoices</b> expect a file with sheets: 'Rates and
                    Summary', 'Delivery Volume', '16 Digit Returns', 'Chargeable
                    Events', '12 Digit Net', 'C2B Volume' and 'SMS'.
                    Or a file only one sheet called 'Delivery Volume'
                  </li>
                  <li>
                    - <b>Evri Int Invoices</b> expect a file with 3 sheets,
                    where sheet number 2 is called Shipments
                  </li>
                   <li>
                    - <b>DPD/ DPD Local Invoices</b> expect a file with 1 sheet,
                    where sheet is called Sheet1 and column A contains 'Account No', 'Nett Invoice Value', 'VAT' ,'Gross Invoice Value' on the first 4 rows
                  </li>
                  <li><br /></li>
                  <li>
                    <b>Steps</b>

                    <ul>
                      <li>1 - Input File(s).</li>
                      <li>
                        2 - The system will try to read each file provided.
                      </li>
                      <li>
                        3 - If all files are valid invoices, a standardised
                        table will be created with all the charges read froom
                        the files.
                      </li>
                      <li>
                        4 - For every charge where customer ref is provided by
                        the invoice, these will be validated against the order
                        numbers in our system, when a customer ref matches an
                        order number, this will be populated on the order number
                        field.
                      </li>
                      <li>
                        5 - For each charge, where order number is still
                        missing, the system will try to automatically get a
                        valid order number based on carrier references provided.
                      </li>
                      <li>
                        6 - Rows that still have order numbers missing will be
                        highlighted in red. These can be manually keyed in.
                      </li>
                      <li>
                        7 - When satisfied with charges, press save charges.
                      </li>
                      <li>
                        8 - If error message saying charges already exist for
                        specified invoice, user can choose to tick overwrite or
                        insert new and keep existing checkboxes and try again.
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
            </v-expand-transition>
          </v-col>
        </v-row>
        <v-divider />
        <!-- standardised data saved -->
        <v-row v-if="standardisedData && standardisedData.length">
          <v-col cols="6">
            <v-card outlined class="pa-4">
              <h1>
                <b
                  >Successfully Parsed: {{ summaryReadFiles.length }}
                  {{ summaryReadFiles.length === 1 ? "file" : "files" }}</b
                >
              </h1>
              <p class="mb-2">
                <b>Total Rows: {{ standardisedData.length }}</b>
              </p>
              <p
                v-if="
                  validOrderNFromCustRef === 0 && invalidOrderNFromCustRef === 0
                "
              >
                Could not validate customer ref 1
              </p>
              <p v-else>
                <b>
                  - Customer ref 1 as order number:
                  {{ validOrderNFromCustRef }} valid,
                  {{ invalidOrderNFromCustRef }} invalid,
                  {{ missingCustRef }} missing
                </b>
              </p>
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-card outlined class="pa-2">
              <v-card-actions>
                <div v-if="mappedCarrierRefsToOrderN.length">
                  Mapped carrier refs to order number:
                  {{ mappedCarrierRefsToOrderN.length }}
                </div>
                <p v-else>No order numbers mapped from carrier refs</p>
                <v-spacer></v-spacer>

                <v-btn
                  icon
                  @click="showMappedCarrierRefs = !showMappedCarrierRefs"
                  small
                  v-if="mappedCarrierRefsToOrderN.length"
                >
                  <v-icon>{{
                    showMappedCarrierRefs
                      ? "mdi-chevron-up"
                      : "mdi-chevron-down"
                  }}</v-icon>
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="showMappedCarrierRefs">
                  <v-data-table
                    :headers="mappedCarrierRefsHeaders"
                    :items="mappedCarrierRefsToOrderN"
                    :items-per-page="5"
                  ></v-data-table>
                </div>
              </v-expand-transition>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="summaryReadFiles.length">
            <!-- loop through each file detail -->
            <v-card
              outlined
              class="mb-2 pb-6 pt-4 px-6"
              v-for="file in summaryReadFiles"
              v-bind:key="file.fileName"
            >
              <div class="text-h6">
                {{ file.fileName }}
              </div>
              <v-divider class="pa-0 mt-2 mb-3" />
              <v-row>
                <v-col cols="4">
                  <h1>
                    <b>Carrier: {{ file.carrier.name }}</b>
                  </h1>
                  <p>Carrier ID: {{ file.carrier.id }}</p>
                  <h1>
                    <b>Invoice number: {{ file.invoiceNumber }}</b>
                  </h1>
                </v-col>
                <!-- loop through sheets -->
                <v-col cols="8">
                  <h1><b>Sheets</b></h1>
                  <v-card
                    v-for="sheet in file.sheets"
                    v-bind:key="sheet.name"
                    outlined
                    class="pa-2"
                  >
                    <v-row>
                      <v-col cols="4"
                        ><p class="mb-0">
                          <b>{{ sheet.name }} </b>
                        </p></v-col
                      >
                      <v-col cols="4"
                        ><p class="mb-0">rows: {{ sheet.numRows }}</p></v-col
                      >
                      <v-col cols="4"
                        ><p class="mb-0">
                          total value: £{{ sheet.totalChargeValue }}
                        </p></v-col
                      >
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-divider class="py-0 my-0" />
          </v-col>
          <v-col cols="3" class="pb-0 mb-0">
            <ExportButtons
              :headers="standardisedHeaders"
              :data="standardisedData"
              :title="'standardised_data'"
            />
          </v-col>
          <v-col cols="9" class="pb-0 mb-0">
            <v-text-field
              v-model="search"
              append-icon="search"
              label="Search"
              single-line
              hide-details
              outlined
              dense
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-data-table
              v-if="standardisedData && standardisedData.length"
              class="elevation-2 my-2"
              :headers="standardisedHeaders"
              :items="standardisedData"
              :items-per-page="10"
              :search="search"
            >
              <template #item="{ item }">
                <tr :class="item.order_number ? '' : 'red lighten-3'">
                  <td>{{ item.carrier_id }}</td>
                  <td>
                    <b>{{ item.invoice_number }}</b>
                  </td>
                  <td>{{ item.invoice_date }}</td>
                  <td>{{ item.transaction_date }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.carrier_ref_1 }}</td>
                  <td>{{ item.carrier_ref_2 }}</td>
                  <td>{{ item.customer_ref_1 }}</td>
                  <td>{{ item.customer_ref_2 }}</td>
                  <td>
                    <v-text-field
                      v-model="item.order_number"
                      dense
                      outlined
                    ></v-text-field>
                  </td>
                  <td>£{{ item.invoice_amount }}</td>
                  <td class="small-text">{{ item.from_file }}</td>
                  <td>{{ item.from_sheet }}</td>
                  <td>
                    <v-btn
                      outlined
                      small
                      color="#B22222"
                      :disabled="loading"
                      @click="deleteStandardisedCharge(item)"
                    >
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-col>
          <!-- save buttons -->
          <v-col cols="12">
            <v-divider class="py-0 my-0" />
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="overwriteStCharges"
              @change="checkboxToggle(1)"
              label="Overwrite existing charges"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-checkbox
              v-model="keepExistingStCharges"
              @change="checkboxToggle(2)"
              label="Insert new and keep existing charges"
            ></v-checkbox>
          </v-col>
          <v-col cols="4">
            <v-btn
              class="mt-2"
              :loading="loading"
              :disabled="loading"
              color="primary"
              @click="saveInvoiceChargesBulk()"
              >Save Charges
            </v-btn>
          </v-col>

          <v-col cols="12">
            <v-divider />
          </v-col>
        </v-row>

        <v-row  v-if="uplodadedInvoicesComb && uplodadedInvoicesComb.length">
         <v-col cols="12">
            <p>Recently uploaded invoices:</p>
            <p  v-for="invoice in uplodadedInvoicesComb" :key="invoice.invoice_number"> 
              - {{invoice.invoice_number}}
            </p>
          </v-col>
        </v-row>
      </v-card>

      <InvoiceRecReport  ref="invoiceRecReport"/>
    </div>

    <v-snackbar v-model="snackbar" :timeout="6000">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false"></v-btn>
    </v-snackbar>
  </div>
</template>
<script>
import Carriers from "@/services/Carriers.js";
import ExportButtons from "@/components/ExportButtons";

import InvoiceRecReport from "@/components/InvoiceRecReport";

import * as XLSX from "xlsx";

import ExcelMixin from "@/mixins/Excel";
import InvoiceRecMixin from "@/mixins/InvoiceRec";
import CommonFunctionsMixin from "@/mixins/BillingCommonFunctions";

export default {
  name: "InvoiceReconciliation",
  components: { ExportButtons, InvoiceRecReport },
  mixins: [ExcelMixin, InvoiceRecMixin, CommonFunctionsMixin],
  data() {
    return {
      upload: false,
      // Snackbar
      snackbar: false,
      text: "",
      response: null,
      search: "",
      loading: false,
      valid: false,

      // Upload
      fileString: "",
      //files
      invoiceFile: "",
      additionalFile: "",
      returnsFile: "",
      //buttons
      invoiceBtnTxt: "Upload Invoice Charges",
      additionalBtnTxt: "Upload Additional Charges",
      returnsBtnTxt: "Upload Returns Charges",

      //report table
      selectedInvoiceWeek: null,
      exportFileName: "Invoice_Report",
      headers: [
        {
          text: "Customer Reference Number",
          align: "left",
          value: "customer_reference_number_one",
        },
        { text: "No Of Parcels", value: "NoOfParcels" },
        { text: "Hermes Cost", value: "hermes Cost" },
        { text: "Label Cost", value: "Label Cost" },
        { text: "Total Cost", value: "TotalCost" },
        { text: "Total Revenue 2", value: "TotalRevenue2" },
        { text: "Total Markup", value: "Total Markup" },
        { text: "Margin %", value: "Margin%" },
      ],
      totalCarrierCost: null,
      totalLabelCost: null,
      totalCost: null,
      totalRevenue: null,
      totalMarkup: null,
      totalMargin: null,
      reportData: [],

      //list of carriers for v-select
      selectedCarrier: { value: "hermes", title: "Hermes" },
      carriersList: [
        { value: "hermes", title: "Hermes" },
        { value: "royal_mail", title: "Royal Mail" },
        { value: "dpd", title: "DPD" },
        { value: "parcelforce", title: "ParcelForce" },
        { value: "dhl", title: "DHL" },
        { value: "ptwop", title: "P2P" },
      ],
      invoiceWeeks: [],

      shipmentCarrier: "evri-int", // default
      shipmentCarrierList: [{ value: "evri-int", title: "Evri International" }],
      shipmentInvoiceFile: null,
      shipmentInvoiceUploading: false,

      sheet: null,
      summaryReadFiles: [],
      standardisedData: [],
      validOrderNFromCustRef: 0,
      invalidOrderNFromCustRef: 0,
      missingCustRef: 0,
      standardisedHeaders: [
        { text: "carrier id", value: "carrier_id", width: "5%" },
        { text: "invoice number", value: "invoice_number", width: "5%" },
        { text: "invoice date", value: "invoice_date", width: "110px" },
        { text: "transaction date", value: "transaction_date", width: "120px" },
        { text: "description", value: "description", width: "150px" },
        { text: "carrier ref 1", value: "carrier_ref_1", width: "5%" },
        { text: "carrier ref 2", value: "carrier_ref_2", width: "5%" },
        { text: "customer ref 1", value: "customer_ref_1", width: "150px" },
        { text: "customer ref 2", value: "customer_ref_2", width: "100px" },
        {
          text: "order number",
          value: "order_number",
          sortable: false,
          width: "200px",
        },
        { text: "invoice amount", value: "invoice_amount", width: "5%" },
        { text: "from file", value: "from_file", width: "100px" },
        { text: "from sheet", value: "from_sheet", width: "10%" },
        { text: "actions", value: "", width: "5%" },
      ],
      carriers: [],
      showLegacy: false,
      overwriteStCharges: false,
      keepExistingStCharges: false,
      mappedCarrierRefsToOrderN: [],
      mappedCarrierRefsHeaders: [
        { text: "carrier reference", value: "reference" },
        { text: "mapped order number", value: "order_number" },
        { text: "tbl ref", value: "tbl_ref" },
      ],
      showMappedCarrierRefs: false,
      showPassword: false,
      filePassword: "rkykq5gg",
      showInfo: false,

      uplodadedInvoicesComb: [],
    };
  },
  created() {
    this.loadInvoiceWeeks(); // NEW - call getData() when the instance is created
    // this.calculateTotals();
    this.getCarriers();
  },
  methods: {
    async getCarriers() {
      return Carriers.getCarriers().then((carriers) => {
        this.carriers = carriers;
      });
    },
    async handleFileUploadInvoice(ev) {
      this.invoiceFile = ev[0];
    },
    async handleFileUploadCharges(ev) {
      this.additionalFile = ev[0];
    },
    async handleFileUploadReturns(ev) {
      this.returnsFile = ev[0];
    },
    async uploadInvoiceFile() {
      if (!this.invoiceFile) {
        this.snackbar = true;
        this.text = `Please select an Invoice Charges file to be uploaded.`;
        this.loading = false;
        return false;
      }
      if (!this.selectedCarrier) {
        this.snackbar = true;
        this.text = `Please select a Carrier`;
        this.loading = false;
        return false;
      }
      this.loading = true;
      var formData = new FormData();
      formData.append("file", this.invoiceFile);
      formData.append("carrier", this.selectedCarrier.value);
      Carriers.uploadCharges(formData, "invoice_charges")
        .then(
          ((response) => {
            this.$set(this, "response", response);
            this.loading = false;
            if (response.status == 200) {
              this.text = response.successMessage[0];
              this.invoiceBtnTxt = "✔";
              this.loadInvoiceWeeks();
            } else {
              this.text = response.errorMessage[0];
              if (response.status == 409) {
                this.invoiceBtnTxt = "✔";
              }
            }

            this.snackbar = true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = error.errorMessage[0];
          this.loading = false;
          return false;
        });
    },
    async uploadAdditionalChargesFile() {
      if (!this.additionalFile) {
        this.snackbar = true;
        this.text = `Please select an Additional Charges file to be uploaded.`;
        this.loading = false;
        return false;
      }
      if (!this.selectedCarrier) {
        this.snackbar = true;
        this.text = `Please select a Carrier`;
        this.loading = false;
        return false;
      }
      this.loading = true;
      var formData = new FormData();
      formData.append("file", this.additionalFile);
      formData.append("carrier", this.selectedCarrier.value);
      Carriers.uploadCharges(formData, "additional_charges")
        .then(
          ((response) => {
            this.$set(this, "response", response);
            this.loading = false;
            if (response.status == 200) {
              this.text = response.successMessage[0];
              this.additionalBtnTxt = "✔";
            } else {
              this.text = response.errorMessage[0];
              if (response.status == 409) {
                this.additionalBtnTxt = "✔";
              }
            }

            this.snackbar = true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = error.errorMessage[0];
          this.loading = false;
          return false;
        });
    },
    async uploadReturnsChargesFile() {
      if (!this.returnsFile) {
        this.snackbar = true;
        this.text = `Please select a Returns Charges file to be uploaded.`;
        this.loading = false;
        return false;
      }
      if (!this.selectedCarrier) {
        this.snackbar = true;
        this.text = `Please select a Carrier`;
        this.loading = false;
        return false;
      }
      this.loading = true;
      var formData = new FormData();
      formData.append("file", this.returnsFile);
      formData.append("carrier", this.selectedCarrier.value);
      Carriers.uploadCharges(formData, "returns_charges")
        .then(
          ((response) => {
            this.$set(this, "response", response);
            this.loading = false;
            if (response.status == 200) {
              this.text = response.successMessage[0];
              this.returnsBtnTxt = "✔";
            } else {
              this.text = response.errorMessage[0];
              if (response.status == 409) {
                this.returnsBtnTxt = "✔";
              }
            }

            this.snackbar = true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = error.errorMessage[0];
          this.loading = false;
          return false;
        });
    },
    resetForm() {
      this.invoiceBtnTxt = "Upload Invoice Charges";
      this.additionalBtnTxt = "Upload Additional Charges";
      this.returnsBtnTxt = "Upload Returns Charges";
      this.exportFileName = "Invoice_Report";
      this.loadInvoiceWeeks();
    },
    async loadInvoiceReport() {
      this.reportData = [];
      if (!this.selectedInvoiceWeek) {
        this.snackbar = true;
        this.text = `Please select a Week`;
        return false;
      }
      if (!this.selectedCarrier) {
        this.snackbar = true;
        this.text = `Please select a Carrier`;
        return false;
      }
      this.loading = true;
      Carriers.getInvoiceReport(
        this.selectedCarrier.value,
        this.selectedInvoiceWeek
      )
        .then(
          ((response) => {
            this.$set(this, "reportData", response.data);
            if (response.status == 200) {
              this.text = response.successMessage[0];
              this.exportFileName =
                "Invoice_Report_" +
                this.selectedCarrier.value +
                this.selectedInvoiceWeek;
              this.calculateTotals();
            } else {
              this.text = response.errorMessage[0];
            }
            this.loading = false;
            this.snackbar = true;
          }).bind(this)
        )
        .catch((error) => {
          this.snackbar = true;
          this.text = error.errorMessage[0];
          this.loading = false;
          return false;
        });
    },
    async loadInvoiceWeeks() {
      this.invoiceWeeks = [];
      this.selectedInvoiceWeek = null;
      this.loading = true;
      Carriers.getInvoiceWeeks(this.selectedCarrier.value).then(
        ((response) => {
          if (response.status == 200) {
            this.text = response.successMessage[0];
            this.$set(this, "invoiceWeeks", response.data);
          } else {
            this.text = response.errorMessage[0];
          }
          this.loading = false;
          this.snackbar = true;
        }).bind(this)
      );
    },
    async calculateTotals() {
      this.totalCarrierCost = 0;
      this.totalLabelCost = 0;
      this.totalCost = 0;
      this.totalRevenue = 0;
      this.totalMarkup = 0;
      this.reportData.forEach((row) => {
        //mysql can sometimes still pass values with a lot of decimal places even when round is used so it can be rounded again to prevent this
        row["hermes Cost"] = parseFloat(row["hermes Cost"]) || 0;
        row["Label Cost"] = parseFloat(row["Label Cost"]) || 0;
        row["TotalCost"] = parseFloat(row["TotalCost"]) || 0;
        row["TotalRevenue2"] = parseFloat(row["TotalRevenue2"]) || 0;
        row["Total Markup"] = parseFloat(row["Total Markup"]) || 0;

        row["hermes Cost"] = row["hermes Cost"].toFixed(2);
        row["Label Cost"] = row["Label Cost"].toFixed(2);
        row["TotalCost"] = row["TotalCost"].toFixed(2);
        row["TotalRevenue2"] = row["TotalRevenue2"].toFixed(2);
        row["Total Markup"] = row["Total Markup"].toFixed(2);
        //the || 0 means if null just place it as 0
        this.totalCarrierCost += parseFloat(row["hermes Cost"]) || 0;
        this.totalLabelCost += parseFloat(row["Label Cost"]) || 0;
        this.totalCost += parseFloat(row["TotalCost"]) || 0;
        this.totalRevenue += parseFloat(row["TotalRevenue2"]) || 0;
        this.totalMarkup += parseFloat(row["Total Markup"]) || 0;
      });
      //round to 2 decimal places
      this.totalCarrierCost = this.totalCarrierCost.toFixed(2);
      this.totalLabelCost = this.totalLabelCost.toFixed(2);
      this.totalCost = this.totalCost.toFixed(2);
      this.totalRevenue = this.totalRevenue.toFixed(2);
      this.totalMarkup = this.totalMarkup.toFixed(2);
      this.totalMargin = ((this.totalMarkup / this.totalRevenue) * 100).toFixed(
        2
      );
    },
    // Evri International Invoice
    handleShipmentInvoiceFile(ev) {
      this.shipmentInvoiceFile = ev;
    },
    async uploadShipmentInvoiceFile() {
      if (!this.shipmentInvoiceFile) {
        this.snackbar = true;
        this.text = `Please select a Shipment Invoice file to be uploaded.`;
        this.shipmentInvoiceUploading = false;
        return false;
      }
      if (!this.shipmentCarrier) {
        this.snackbar = true;
        this.text = `Please select a Carrier`;
        this.shipmentInvoiceUploading = false;
        return false;
      }
      this.shipmentInvoiceUploading = true;
      let formData = new FormData();
      formData.append("file", this.shipmentInvoiceFile);
      await Carriers.uploadShipmentInvoice(formData, this.shipmentCarrier)
        .then((response) => {
          if (response.status === 201) {
            this.text = `${response.count} records stored from ${response.file}`;
          }
        })
        .catch((err) => {
          if (err.response && err.response.message) {
            this.text = err.response.message;
          } else if (err.response) {
            this.text = err.response;
          } else {
            this.text = err;
          }
        })
        .then(() => {
          this.shipmentInvoiceUploading = false;
          this.handleShipmentInvoiceFile(null); // reset the shipmentInvoiceFile v-model
          this.$refs.shipmentInvoiceInput.clearableCallback(); // clear the file input; .clearableCallback(): v-file-input specific
          this.snackbar = true;
        });
    },

    resetStandardisedCharges() {
      this.search = "";
      this.summaryReadFiles = [];
      this.standardisedData = [];
      this.mappedCarrierRefsToOrderN = [];
      this.showMappedCarrierRefs = false;
      this.overwriteStCharges = false;
      this.keepExistingStCharges = false;
      this.validOrderNFromCustRef = 0;
      this.invalidOrderNFromCustRef = 0;
      this.missingCustRef = 0;
    },
    resetFileInput() {
      this.$refs.fileInput.$refs.input.value = null;
      this.$refs.fileInput.reset();
    },

    validateDuplicateFiles(ev) {
      //validate duplicate files
      if (ev.length > 1) {
        const duplicateFiles = this.findDuplicateAttribute(ev, "size");
        if (duplicateFiles) {
          const fileNameOne = duplicateFiles.obj1.name;
          const fileNameTwo = duplicateFiles.obj2.name;
          const msg = `Potential duplicate files detected: \n"${fileNameOne}" and "${fileNameTwo}" share the same file size`;
          confirm(msg);
        }
      }
    },
    parseExcelDates(xlsxData){
      for (const sheetName in xlsxData.Sheets) {
        const sheet = xlsxData.Sheets[sheetName];
        // Loop through each attribute in the sheet
        for (const attributeName in sheet) {
          const attribute = sheet[attributeName];
          // Check if the type is 'd' (date)
          if (attribute.t && attribute.t === 'd') {
            // Assign the 'w' property to 'v'
            attribute.v = attribute.w;
          }
        }
      }
      return xlsxData
    },
    async handleInvoiceCarrierFiles(ev) {
      this.resetStandardisedCharges();
      if (ev.length < 1) return;
      this.loading = true;
      try {
        this.validateDuplicateFiles(ev);
        let combined = [];
        for (let carriersFile of ev) {
          let binaryFile = await this.readInvoiceFile(carriersFile);
          let parsedFile = await this.parseBinaryFile(binaryFile);
          let parsedExcelDates = this.parseExcelDates(parsedFile)
          const fileName = carriersFile.name;
          let charges = this.mapCarrierExtraction(parsedExcelDates, fileName);

          combined = combined.concat(charges);
        }
        const validatedData = await this.matchInvoiceDataOrderNumber(combined);
        const mappedData = await this.findChargesOrderNumber(validatedData);
        await this.validateInvoiceExists(mappedData);
        this.standardisedData = this.sortByEmptyAttributeFirst(
          mappedData,
          "order_number"
        );
      } catch (err) {
        this.activateSnackbar(err.message);
      } finally {
        this.loading = false;
      }
    },
    async readInvoiceFile(file) {
      var fileReader = new FileReader();
      return new Promise((resolve) => {
        fileReader.onload = async function (e) {
          const binaryStr = e.target.result;

          resolve(binaryStr);
        };
        fileReader.readAsBinaryString(file);
      });
    },
    async parseBinaryFile(binaryStr) {
      try {
        let oFile = XLSX.read(binaryStr, {
          type: "binary",
          cellFormula: true,
          cellDates: true,
          sheetStubs: true,
        });
        return oFile;
      } catch (err) {
        if (err.message === "File is password-protected") {
          this.activateSnackbar(
            "password-protected file detected, attempting to decrypt using default password"
          );
          const decrypted = await this.decryptPassword(
            binaryStr,
            this.filePassword
          );
          let oFile = XLSX.read(decrypted, {
            type: "buffer",
            cellFormula: true,
            cellDates: true,
            sheetStubs: true,
          });
          return oFile
        } else {
          throw err;
        }
      }
    },
    async findChargesOrderNumber(charges) {
      let referencesToMap = [];
      for (const charge of charges) {
        // skip charges which already have order_number populated
        if (charge.order_number) continue;
        if (charge.carrier_ref_1) {
          referencesToMap.push(charge.carrier_ref_1);
        }
        if (charge.carrier_ref_2) {
          referencesToMap.push(charge.carrier_ref_2);
        }
        if (charge.customer_ref_1) {
          referencesToMap.push(charge.customer_ref_1);
        }
        if (charge.customer_ref_2) {
          referencesToMap.push(charge.customer_ref_2);
        }
        
      }
      if (referencesToMap.length) {
        let mappedReferences = await this.getOrderNumbersFromReferences(
          referencesToMap
        );
        if (mappedReferences) {
          this.mappedCarrierRefsToOrderN = mappedReferences;
          charges = this.mapReferencesToCharges(charges, mappedReferences);
        }
      }

      return charges;
    },
    mapReferencesToCharges(charges, references) {
      for (const charge of charges) {
        if (charge.order_number) continue;
        const matched = references.find((orderNumber) => {
          return (
            charge.carrier_ref_1 === orderNumber.reference ||
            charge.carrier_ref_2 === orderNumber.reference ||
            charge.customer_ref_1 === orderNumber.reference ||
            charge.customer_ref_2 === orderNumber.reference
          );
        });
        if (matched) {
          charge.order_number = matched.order_number;
        }
      }
      return charges;
    },

    async getOrderNumbersFromReferences(references) {
      try {
        this.loading = true;
        let reqBody = {
          references: references,
        };
        let response = await Carriers.findOrderNumbersFromReferences(reqBody);
        this.loading = false;
        return response.data;
      } catch (error) {
        this.loading = false;
        const text = error.response
          ? error.response.data.message
          : `${error.message}`;
        this.activateSnackbar(text);
        return null;
      }
    },
    async matchInvoiceDataOrderNumber(data) {
      for (const row of data) {
        if(row.customer_ref_1){
          row.order_number = typeof row.customer_ref_1 === 'string' ? row.customer_ref_1.replace(/\s/g, "") : row.customer_ref_1
        }else{
          row.order_number = ""
        }
     
      }
      // validate order numbers
      let validated = await this.validateChargesOrderNumbers(data);
      return validated;
    },
    async validateInvoiceExists(charges) {
      let combinations = this.getAllInvoiceCombinations(charges);
      let msg = "";
      let exists = false;
      for (const comb of combinations) {
        try {
          const result = await Carriers.validateInvoiceExists(comb);
          if (result.data && result.data.exists) {
            exists = true;
            msg += `Charges for invoice ${comb.invoice_number} for carrier ID ${comb.carrier_id} already uploaded. `;
          }
        } catch {
          continue;
        }
      }
      if (exists) {
        confirm(msg);
      }
    },
    saveInvoiceChargesBulk() {
      this.loading = true;
      let reqBody = {
        overwrite: this.overwriteStCharges,
        ignoreExisting: this.keepExistingStCharges,
        insertData: this.standardisedData,
      };
      Carriers.saveInvoiceCharges(reqBody)
        .then((response) => {
          this.resetStandardisedCharges();
          this.resetFileInput();
          this.uplodadedInvoicesComb = this.getAllInvoiceCombinations(response.data)
          this.$refs.invoiceRecReport.loadInitialData()
          this.activateSnackbar("invoice charges saved");
        })
        .catch((error) => {
          const text = error.response
            ? error.response.data.message
            : `${error.message}`;
          this.activateSnackbar(text);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async validateChargesOrderNumbers(charges) {
      const orderNumbers = charges.map((el) => el.order_number);

      const results = await this.validateOrderNumbers(orderNumbers);

      // cant validate
      if (!results) return charges;

      const invalidOrderNumbers = results.invalidOrderNumbers;
      const validOrderNumbers = results.validOrderNumbers;
      let validCounter = 0;
      let invalidCounter = 0;
      let missing = 0;
      // clear order_number field if reference is in invalidOrderNumbers array
      for (const charge of charges) {
        if (!charge.order_number) {
          missing++;
        } else if (invalidOrderNumbers.includes(charge.order_number)) {
          charge.order_number = "";
          invalidCounter++;
        } else if (validOrderNumbers.includes(charge.order_number)) {
          validCounter++;
        }
      }

      this.validOrderNFromCustRef = validCounter;
      this.invalidOrderNFromCustRef = invalidCounter;
      this.missingCustRef = missing;
      return charges;
    },

    async validateOrderNumbers(orderNumbers) {
      try {
        this.loading = true;
        let reqBody = {
          orderNumbers: orderNumbers,
        };
        let response = await Carriers.validateOrderNumbers(reqBody);
        this.loading = false;
        return response.data;
      } catch (error) {
        this.loading = false;
        const text = error.response
          ? error.response.data.message
          : `${error.message}`;
        this.activateSnackbar(text);
        return null;
      }
    },
    deleteStandardisedCharge(charge) {
      const index = this.standardisedData.indexOf(charge);
      // remove charge
      this.standardisedData.splice(index, 1);
    },

    activateSnackbar(text) {
      this.text = text;
      this.snackbar = true;
    },
    checkboxToggle(checkbox) {
      switch (checkbox) {
        case 1:
          if (this.overwriteStCharges) {
            this.keepExistingStCharges = false;
          }
          break;
        case 2:
          if (this.keepExistingStCharges) {
            this.overwriteStCharges = false;
          }
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
p {
  font-size: 0.8em;
}

.description {
  margin-bottom: 30px;
}

.error {
  background-color: red;
}
.v-text-field--outlined {
  border-color: black;
}
.small-text {
  font-size: 0.7em !important;
}
</style>
